
<template>
  <div id="header">
    <img id="header-logo" src="@/assets/minial_logo_gray.png">
  </div>

  <div id="main">
    <router-view />
  </div>
  <div id="footer">
    <div id="footer_list">
      <ul>
        <li><a href="https://minial.jp" target="_blank">Minialについて</a></li>
        <li><a href="https://minial.jp/document/termsOfService.pdf" target="_blank">利用規約</a></li>
        <li><a href="https://minial.jp/document/privacyPolicy.pdf" target="_blank">プライバシーポリシー</a></li>
        <li><a href="https://minial.jp/document/legalMatters.pdf" target="_blank">特定商取引法に基づく表示</a></li>
      </ul>
      <p id="copyright">copyright&copy; <a href="https://newsco.jp/design.html" target="_blank">news co., ltd.</a></p>
    </div>
  </div>
</template>

<script>
export default {
  data: function () {
    return { open: false };
  },
}
</script>


<style>
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500&family=Zen+Kaku+Gothic+New:wght@400;500;700&family=Zen+Maru+Gothic:wght@400;500;700&display=swap');


#app {
  font-family: 'Inter', 'Zen Maru Gothic', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  padding: none;
  margin: none;
  height: 100%;
  overflow: hidden;
}

html {
  font-size: 62.5%;
}

@media screen and (min-width: 1000px) {
  html {
    font-size: 60%;
  }
}



h1,
h2,
h3,
h4,
h5,
h6,
p,
a {
  padding: 0;
  margin: 0;
  letter-spacing: 1.6;
  color: #222;
  -webkit-tap-highlight-color: transparent;
}

a {
  -webkit-tap-highlight-color: transparent;
  text-decoration: none;
}

h3 {
  color: #18AFB2;
  font-size: calc(2.4rem + 0.4vw);
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 4px;
}

h4 {
  color: #18AFB2;
  font-family: 'Inter';
  font-size: calc(1rem + 0.5vw);
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 2px;
}


body {
  margin: 0 auto;
  padding: 0;
  max-width: 1200px;

}

button {
  background-color: none;
  border: none;
  padding: 0;
  margin: 0;
  border: none;
  background-color: transparent;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}

button:disabled {
  cursor: default;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #18AFB2;
}

#main {
  min-height: calc(100vh - 100px);
}

#header {
  padding: 0 24px;
  height: 60px;
  display: flex;
  justify-content: start;
  align-items: center;
}

#header-logo {
  height: 25%;
}

#footer {
  height: 40px;
}


#footer_list {
  margin: 0;
  display: flex;
  justify-content: space-between;
}

#footer_list ul {
  list-style: none;
  padding: 0 24px;
  text-align: left;
}

#footer_list ul li {
  padding: 0;
  display: inline-block;
  font-size: calc(0.8rem + 0.5vw);
  padding-right: 24px;
}

#footer_list ul li a {
  color: #B5B5B5;
  font-weight: bold;
  font-size: 1.1rem;
}

#footer #copyright {
  margin: 8px 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
}

#copyright,
#copyright a {
  color: #B5B5B5;
  font-size: 1.0rem;
}

.center {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
}

@media screen and (max-width:700px) {



  #header {
    padding: 0 16px;
  }

  #footer {
    height: auto;
  }

  #footer_list {
    display: block;
    padding: 8px 0;
  }

  #footer_list ul li {
    display: block;
    padding: 4px 0;
  }

  #footer #copyright {
    display: block;
    text-align: left;
    height: auto;
  }
}
</style>