<template>
    <div id="passcode-input">
        <div id="passcode-input-title">
            <p v-if="!this.invalidPasscodeCheck">パスコードを入力してください</p>
            <p v-if="this.invalidPasscodeCheck" style="color: red;">パスコードが違います。</p>
        </div>
        <div id="passcode-input-box">
            <input v-for="i of 4" :ref="'input-' + i" :key="i" type="tel" class="passcode-input-number"
                :id="'passcode-input-number-' + i" :class="i" @input="onInput($event, i)" placeholder=" "
                @keydown="onKeydown($event, i)" maxlength="1" @focus="onFocus(i)" />
        </div>
    </div>
</template>

<script>
export default {
    components: {},
    data: function () {
        return {
            invalidPasscodeCheck: false,
        };
    },
    mounted: function () {
        setTimeout(function () { document.getElementById("passcode-input-number-1").focus() }, 1200)
    },
    methods: {
        // フォーカスされた場合の処理
        onFocus: function (refId) {
            // 現在の位置以降のinputに値がある場合はそれをフォーカスする
            for (let i = 4; i > refId; i--) {
                let checkId = "passcode-input-number-" + (i - 1).toString()
                let element = document.getElementById(checkId);
                if (element.value != '') {
                    document.getElementById("passcode-input-number-" + i.toString()).focus()
                    break
                }
            }
        },
        // 数字がインプットされた場合の処理
        onInput: function (event, refId) {
            let thisElement = document.getElementById("passcode-input-number-" + refId.toString());
            // 数字以外を却下する
            if (!/^([1-9]\d*|0)$/.test(thisElement.value)) {
                thisElement.value = ''
                return
            }
            // 数字だった場合はチェックする
            if (event.data != null && event.data.length == 1) {
                if (refId != 4) {
                    // 最後ではない場合は次をfocusする
                    let nextRef = refId + 1;
                    let nextId = "passcode-input-number-" + nextRef.toString()
                    document.getElementById(nextId).focus()
                } else {
                    // 4番だった場合はフォーカスを外す
                    thisElement.blur()
                    // 親要素にemitする
                    let completeValue = '';
                    for (let i = 1; i <= 4; i++) {
                        let checkId = "passcode-input-number-" + i.toString()
                        completeValue += document.getElementById(checkId).value.toString();
                    }
                    this.$emit('onComplete', completeValue)
                }
            }

        },
        // DeleteとBackspaceで消された場合の取得
        onKeydown: function (event, refId) {
            if (event.key == '0') return
            // delete & backspace
            if (event.keyCode == 48 || event.keyCode == 8) {
                // 現在のフォーカス位置の
                let thisElement = document.getElementById("passcode-input-number-" + refId.toString());
                let thisElementHasValue = thisElement.value != '';
                thisElement.value = '';
                // 最初のインプットではなく、かつ、現在の値が入っていなかった場合
                if (refId != 1 && !thisElementHasValue) {
                    // 1つまえのinputの値を消して、そちらに遷移する
                    let prevRef = refId - 1;
                    let prevId = "passcode-input-number-" + prevRef.toString()
                    let prevElement = document.getElementById(prevId);
                    prevElement.value = ''
                    prevElement.focus()
                } else if (refId == 1) {
                    // 最初のInputだった場合はfocusを外す
                    //thisElement.blur();
                }
            }
        },
        // 全ての値をリセットする
        resetValue: function () {
            let elements = document.getElementsByClassName('passcode-input-number')
            for (let i = 0; i < elements.length; i++) {
                elements[i].value = ''
            }
            document.getElementById("passcode-input-number-1").focus()
        },
        // パスワードが違った場合
        invalidPasscode: function () {
            this.resetValue()
            this.invalidPasscodeCheck = true
        }
    },
}

</script>

<style scoped>
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

#passcode-input {
    padding: 40px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#passcode-input-title p {
    color: #18AFB2;
    font-weight: bold;
    font-size: 1.4rem;
    margin-bottom: 20px;
}

#passcode-input-box {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

}

.passcode-input-number {
    width: 48px;
    height: 48px;
    padding: 0;
    margin: 0;
    text-align: center;
    font-size: 2.4rem;
    margin: 2px;
    border: 3px solid white;
    border-radius: 18px;
    transition: .3s;
    color: white;
    background-color: #18AFB2;
}

.passcode-input-number:focus {
    outline: none;
    caret-color: rgba(0, 0, 0, 0);
    border-radius: 8px;
    border: 3px solid #18AFB2;
}

input:placeholder-shown {
    background-color: #F2F4F6;
}

#passcode-invalid {
    color: red;
    font-weight: bold;
    margin-top: 16px;
    font-size: 1.2rem;
}
</style>