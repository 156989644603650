<template>
    <div class="details" v-if="this.contentsData != null">
        <video v-if="this.contentsData.type == 'video'" controls muted autoplay :src="this.contentsData.video_url"></video>
        <ProgressiveImage v-if="this.contentsData.type == 'image'" object-cover
            :placeholder-src="this.contentsData.image_url_low" :src="this.contentsData.image_url_mid" />
    </div>
    <!--<div id="image-detail-header">
        <a :href="fullsize" download id="image-download-button" target="_blank">
            <Icon icon="fluent:arrow-download-16-filled" width="16px" />
            <p>ダウンロードする</p>
        </a>
    </div>-->
</template>
  
<script>
export default {
    mounted() {
        setTimeout(() => {
            this.showImage = true
            document.title = 'Photo - cheesecake'
        }, 50);
    },
    data: function () {
        return { contentsData: null }
    },
    methods: {
        /// 表示するコンテンツを設定する
        setViewContents(val) {
            this.contentsData = val
        }
    },
    computed: {
        thumbnail: function () {
            // ビデオの場合は使用不可
            if (this.$route.params.type == 'video') return;
            return 'https://storage.googleapis.com/' + this.$store.state.projectId + '.appspot.com/album/' + this.$route.params.id + '/image/thumbnails/' + this.$route.params.image + '_100x100.jpeg';
        },
        url: function () {
            if (this.$route.query.type == 'image') {
                // 写真タイプの場合
                return 'https://storage.googleapis.com/' + this.$store.state.projectId + '.appspot.com/album/' + this.$route.params.id + '/image/thumbnails/' + this.$route.params.image + '_1200x1200.jpeg';
            } else if (this.$route.query.type == 'video') {
                // ビデオタイプの場合
                return 'https://storage.googleapis.com/' + this.$store.state.projectId + '.appspot.com/album/' + this.$route.params.id + '/video/' + this.$route.params.image + '.mp4';
            } else {
                return '';
            }
        },
        fullsize: function () {
            if (this.$route.query.type == 'image') {
                // 写真タイプの場合
                return 'https://storage.googleapis.com/' + this.$store.state.projectId + '.appspot.com/album/' + this.$route.params.id + '/image/thumbnails/' + this.$route.params.image + '_3000x3000.jpeg';
            } else if (this.$route.query.type == 'video') {
                // ビデオタイプの場合
                return 'https://storage.googleapis.com/' + this.$store.state.projectId + '.appspot.com/album/' + this.$route.params.id + '/video/' + this.$route.params.image + '.mp4';
            } else {
                return '';
            }
        },
    },
}
</script>
  
<style scoped>
.details {
    display: block;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    padding: 0 24px;
    max-height: clac(800px - 56px);
}

@media screen and (max-width: 700px) {
    .details {
        padding: 0 16px;
    }
}

video {
    width: 100%;
    height: calc(100% - 56px);
    max-height: calc(100vh - 100px);
    object-fit: contain;
    background-color: rgba(0, 0, 0, 0.4);
}

img {
    width: 100%;
    height: calc(100% - 56px);
    max-height: calc(100vh - 100px - 56px);
    object-fit: contain;
    opacity: 0;
    scale: 1;
}

img.show {
    opacity: 1;
    scale: 1;
    transition: opacity 1s;
}

.desc {
    text-align: center;
    margin-top: 24px;
    max-width: 400px;
}

#image-detail-header {
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
}

#image-download-button {
    margin-top: 24px;
    padding: 16px;
    color: black;
    background-color: #F2F4F6;
    display: flex;
    border-radius: 16px;
}

#image-download-button p {
    font-weight: bold;
    font-size: 1.4rem;
    line-height: 1.2;
    padding-left: 8px;
}
</style>

