<template>
    <div id="index_splash_screen" v-if="isShow" :class="{ hide: !showOpacity }">
        <div id="index_splash_screen_animation">
            <img :src="gif_src + '?ts=' + timestamp" />
        </div>
    </div>
</template>

<script>
export default {
    computed: {

    },
    mounted() {
        setTimeout(() => {
            this.showOpacity = false;
            setTimeout(() => {
                this.isShow = false;
            }, 800);
        }, 1200);
    },
    data: function () {
        return { isShow: true, showOpacity: true, timestamp: Date.now(), gif_src: require("../assets/animation/touch_animation.gif"), };
    },
    methods: {}
}
</script>

<style scoped>
#index_splash_screen {
    z-index: 9999;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #18AFB2;
    opacity: 1;
    transition: all .8s;
    -webkit-transition: all 0.8s;
    -moz-transition: all 0.8s;
    -ms-transition: all 0.8s;
    -o-transition: all 0.8s;
    display: flex;
    justify-content: center;
    align-items: center;
}

#index_splash_screen.hide {
    opacity: 0;
}

#index_splash_screen_animation {
    width: 30vw;
    max-width: 180px;
}

#index_splash_screen_animation img {
    width: 100%;
    object-fit: contain;
}
</style>