
import { createRouter, createWebHistory } from 'vue-router'
//import HomeView from '../views/HomeView.vue'
import ImagesView from '../views/ImagesView.vue'
//import ImageDetail from '../views/ImageDetail.vue'
import NotFound from '../views/NotFound.vue'


const routes = [
  {
    path: '/:id',
    name: 'images',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: ImagesView,
    meta: { title: "Album - Minial" },
    props: true,
  },
  {
    path: '/:id/:image',
    name: 'image detail',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: ImagesView,
    meta: { title: "Photo - Minial" },
    props: true,
  },
  /*{
    path: '/:id/:image',
    name: 'image detail',
    // route level code-splitting
    // this generates a sfeparate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: ImageDetail,
    meta: { title: "Photo - minial" },
    props: true,
  }*/
  /*{
    path: '/',
    name: 'home',
    component: HomeView,
    meta: { title: "minial - 1枚で残す、家族みんなのミニマルアルバム" }
  },
  {
    path: '/i/:id',
    name: 'images',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: ImagesView,
    meta: { title: "Album - minial" },
    props: true,
  },
  {
    path: '/i/:id/:image',
    name: 'image detail',
    // route level code-splitting
    // this generates a sfeparate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: ImageDetail,
    meta: { title: "Photo - minial" },
    props: true,
  },*/
  {
    path: '/404',
    component: NotFound,
    meta: { title: "404 - Minial" },
  },
  {
    path: '/:catchAll(.*)',
    component: NotFound,
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.afterEach((to) => {
  if (to.meta && to.meta.title) {
    document.title = to.meta.title
  }
  // index設定
  // トップページのみindexさせる
  if (to.path == '/' && this.$store.state.projectId == 'cheesecake-prod') {
    // indexページのみindexさせる
    //document.querySelector("meta[name='robots']").setAttribute('content', 'all')
  } else {
    // index以外はnoindexにする
    document.querySelector("meta[name='robots']").setAttribute('content', 'noindex')
  }
})

export default router
