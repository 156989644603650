<template>
    <div id="contents-overlay-viewer" v-show="!hide">
        <div id="contents-header">
            <button v-on:click="setContents(null)">
                <Icon icon="fluent:dismiss-16-filled" width="32px" />
            </button>
        </div>
        <ImageDetail ref="image-view" />
    </div>
</template>

<script>
import ImageDetail from '@/components/ImageDetail.vue'

export default {
    data: function () {
        return { hide: true }
    },
    mounted: function () {
    },
    methods: {
        /// 表示するコンテンツを設定する
        async setContents(val) {
            // 表示と非表示を切り替える
            if (val == null) {
                // 非表示処理
                this.$refs['image-view'].setViewContents(null)
                await (this.hide = true)
            } else {
                // 表示処理
                await (this.hide = false)
                this.$refs['image-view'].setViewContents(val)


            }
        }
    },
    components: { ImageDetail }
}
</script>

<style scoped>
#contents-overlay-viewer {
    background-color: rgba(0, 0, 0, 0.8);
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    opacity: 1;
    z-index: 999;
}

#contents-overlay-viewer.hide {
    left: -100vw;
}

#contents-header {
    padding: 0 24px;
    height: 56px;
    color: white;
    display: flex;
}

@media screen and (max-width:700px) {
    #contents-header {
        padding: 0 16px;
    }
}

#contents-header button {
    color: white;
}
</style>