<template>
  <div id="not-found">
    <div>
      <div class="not-found-box">
        <h1>404 Not found...</h1>
      </div>
      <div class="not-found-box">
        <p>このページは存在しません。</p>
      </div>
      <div class="not-found-box">
        <vue-loaders-pacman color="black" scale="0.5"></vue-loaders-pacman>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'NotFound',
  components: {},
  mounted: function () {
    document.title = '404 - Minial';
  }
}

</script>

<style scoped>
#not-found {
  height: calc(100vh - 40px);
  display: flex;
  justify-content: center;
  vertical-align: middle;
  align-items: center;
}

.not-found-box {
  margin: 8px 0;
}


h1 {
  width: 100%;
  font-size: 24px;
  font-weight: normal;
}

p {
  font-size: 8px;
}
</style>