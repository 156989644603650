<!-- 画像を一覧表示するためのVue -->
<template>
    <SplashScreen />
    <!-- passcode input -->
    <div v-if="requirePassword">
        <PasscodeInput ref="passcode-input" @onComplete="(val) => getData(val)" />
    </div>
    <!-- album view -->
    <div v-if="!requirePassword">
        <!-- タイトル等を表示する領域 -->
        <div id="image-list-captions" v-if="this.$store.state.albumTitle != '' || this.$store.state.albumCaption != ''">
            <div class="image-list-title">
                <h1>{{ this.$store.state.albumTitle }}</h1>
                <p>{{ this.$store.state.albumCaption }}</p>
            </div>
        </div>
        <div id="video-list-view" class="list-view">
            <ul class="video-list grid-list" v-if="this.showImageList">
                <li v-for="(item, i) in this.$store.state.video_url_list" :key="i" class="video-item">
                    <!-- ビデオの場合の表示 -->
                    <button v-on:click="setOverlayContens({ type: 'video', video_url: item })">
                        <video autoplay muted loop playsinline :src="item"></video>
                    </button>
                </li>
            </ul>
        </div>
        <div id="image-list-view" class="list-view">
            <Transition name="image-list-fade">
                <ul class="grid-list" v-if="this.showImageList">
                    <li v-for="(item, i) in this.$store.state.image_url_list_mid" :key="i" class="grid-item">
                        <!-- 画像の場合の表示 -->
                        <button
                            v-on:click="setOverlayContens({ type: 'image', image_url_low: this.$store.state.image_url_list_low[i], image_url_mid: this.$store.state.image_url_list_mid[i], image_url_hi: this.$store.state.image_url_list_hi[i] })">
                            <ProgressiveImage blur="10" object-cover
                                :placeholder-src="this.$store.state.image_url_list_low[i]" :src="item" />
                        </button>
                    </li>
                </ul>
            </Transition>
        </div>
    </div>
    <ContentsOverlayViewer ref="overlay-viewer" />
</template>


<script>
import PasscodeInput from '@/components/PasscodeInput.vue';
import SplashScreen from '@/components/SplashScreen.vue';
import ContentsOverlayViewer from '@/components/ContentsOverlayViewer.vue';


export default {
    async mounted() {
        this.getData('')
    },
    data: function () {
        return {
            showCopyDone: false,
            showImageList: false,
            requirePassword: false,
            items: [],
            videoItems: [],
        };
    },
    computed: {
        id: function () {
            return this.$route.params.id;
        },
    },
    methods: {
        getData: async function (password) {
            // 取得済みでなかった場合はデータを取得する
            let res = await this.$store.dispatch('getAlbum', { 'id': this.$route.params.id, 'password': password })
            // パスワードが必要な場合
            if (res == 'REQUIRE_PASSWORD') {
                // パスワードが必要な場合の処理
                this.requirePassword = true
                if (this.$refs['passcode-input'] != null) {
                    this.$refs['passcode-input'].invalidPasscode()
                }
                return
            } else {
                // パスワードが入らなくなった処理
                this.requirePassword = false
            }

            // データがなかった場合は404に遷移する
            if ((this.$store.state.imageIds == null || this.$store.state.imageIds.length == 0) && (this.$store.state.videoIds == null || this.$store.state.videoIds.length == 0)) {
                this.$router.replace({ path: '/404' });
            }

            // タイトルの設定
            if (this.$store.state.albumTitle == '') {
                document.title = 'Album - Minial'
            } else {
                document.title = this.$store.state.albumTitle + ' | Album - Minial';
            }

            // データがある場合は処理を継続、画像データを処理する
            this.items = [];
            // ビデオデータ
            if (this.$store.state.videoIds != null) {
                for (var v in this.$store.state.videoIds) {
                    this.videoItems.push({
                        'type': 'video',
                        'id': this.$store.state.videoIds[v],
                        'url': this.$store.state.videoUrls[v],
                    });
                }
            }
            // 写真データ
            if (this.$store.state.imageIds != null) {
                for (var i in this.$store.state.imageIds) {
                    this.items.push({
                        'type': 'image',
                        'id': this.$store.state.imageIds[i],
                        'thumbnail': this.$store.state.thumbnailImages[i],
                        'url': this.$store.state.images[i]
                    });
                }
            }
            // データの取得処理が完了したら表示
            this.showImageList = true;
        },
        /// 全画面で表示するコンテンツを設定する
        setOverlayContens: function ({ type, image_url_low, image_url_mid, image_url_hi, video_url }) {
            this.$refs['overlay-viewer'].setContents({
                'type': type,
                'image_url_low': image_url_low,
                'image_url_mid': image_url_mid,
                'image_url_hi': image_url_hi,
                'video_url': video_url,
            });
        },
        /// クリップボードにコンテンツを設定する
        copyString: function (val) {
            copyToClipboard(val);
            // クリップボードへのコピー完了を表示する
            this.showCopyDone = true;
            setTimeout(() => {
                this.showCopyDone = false;
            }, 2000);
        },

    },
    components: { SplashScreen, PasscodeInput, ContentsOverlayViewer }
}


function copyToClipboard(tagValue) {
    if (navigator.clipboard) {
        navigator.clipboard.writeText(tagValue)
    }
}

</script>
  
<style>
#image-list-captions {
    width: calc(100% - 48px);
    padding: 80px 24px;
}

.image-list-title {
    text-align: left;
}

.image-list-title h1 {
    color: black;
    font-size: 3.2rem;
    font-weight: 500;
    letter-spacing: 0.2rem;
    margin-bottom: 4px;
}

.image-list-title p {
    color: black;
    font-size: 1.2rem;
    font-weight: 400;
    letter-spacing: 0.1rem;
}



.list-view {
    padding: 24px;
}

/* enter-active -> enter-to の順番で書くのが大事*/
.image-list-fade-enter-active {
    transition: opacity .5s;
    opacity: 1;
}

.image-list-fade-enter-to {
    opacity: 1;
}

/* leave-active -> leave-to の順番で書くのが大事*/
.image-list-fade-leave-active {
    transition: opacity .2s;
    opacity: 1;
}

.image-list-fade-leave-to {
    opacity: 1;
}

#image-list-loading-animation {
    position: fixed;
    top: 0;
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    vertical-align: middle;
}

.images-image {
    width: 100%;
}

.grid-list {
    list-style: none;
    padding: 0;
    margin: 0;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(170px, 1fr));
    column-gap: 16px;
    row-gap: 16px;
    transition: .4s;
}

.grid-item {
    background-color: #eeeeee;
    display: block;
    content: "";
    aspect-ratio: 1;
    overflow: hidden;
    border-radius: 16px;
}

.grid-item:first-child {
    grid-row: span 2;
    grid-column: span 2;
}

.grid-item button {
    width: 100%;
    height: 100%;
    border: none;
    padding: 0;
    margin: 0;
}

.grid-item button:active {
    scale: 0.95;
    transition: all 800ms cubic-bezier(.5, 3, .5, 1);
}

.grid-item button img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}


.video-list {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1rem));
}

.video-item {
    aspect-ratio: 1;
}

.video-item button {
    width: 100%;
    height: 100%;
    border: none;
    padding: 0;
    margin: 0;
}

.video-item button video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 100px;
}

@media screen and (max-width:700px) {
    #image-list-captions {
        padding: 48px 16px;
    }

    .list-view {
        padding: 16px;
    }

    .grid-list {
        grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
        column-gap: 8px;
        row-gap: 8px;
    }

    .video-list {
        grid-template-columns: repeat(auto-fit, minmax(110px, 1rem));
        justify-content: center;
    }

}
</style>