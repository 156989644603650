
import { createStore } from 'vuex'
import axios from 'axios'

export default createStore({
    state: {
        //project id
        //projectId: 'cheesecake-dev-394900',
        projectId: 'cheesecake-prod',
        // 
        completeSubscribeNewsLetter: false,
        albumId: null,
        albumTitle: '',
        albumCaption: '',
        albumUserName: '',
        imageIds: [],
        videoIds: [],
        thumbnailImages: [],
        images: [],
        videoUrls: [],
        image_url_list_low: [],
        image_url_list_mid: [],
        image_url_list_hi: [],
        video_url_list: [],
    },
    mutations: {
        // 画像データを全て取得する
        setImageUrls(state, { albumId, imageIds, short, tall, videoIds, videoUrls, image_url_list_low, image_url_list_mid, image_url_list_hi, video_url_list, title, caption, user, }) {
            state.albumId = albumId
            state.imageIds = imageIds
            state.thumbnailImages = short
            state.images = tall
            state.videoIds = videoIds;
            state.videoUrls = videoUrls;
            state.albumTitle = title ?? ''
            state.albumCaption = caption ?? ''
            state.albumUserName = user ?? ''
            state.image_url_list_low = image_url_list_low
            state.image_url_list_mid = image_url_list_mid
            state.image_url_list_hi = image_url_list_hi
            state.video_url_list = video_url_list
        },
    },
    actions: {
        // アルバムデータを取得する
        async getAlbum(_, data) {
            try {
                const url = 'https://' + _.state.projectId + '.an.r.appspot.com/album/' + data.id.toString();
                let res = await axios.post(url, { 'password': data.password });
                if (res != null) {
                    console.log(res);

                    // URLを作成する
                    const basePath = 'https://storage.googleapis.com/' + _.state.projectId + '.appspot.com/album/' + data.id
                    // 画像系の処理
                    const imageIds = []
                    const short = []
                    const tall = []
                    for (var i in res.data.image_list) {
                        const id = res.data.image_list[i];
                        imageIds.push(id)
                        short.push(basePath + '/image/thumbnails/' + id + '_100x100.jpeg')
                        tall.push(basePath + '/image/thumbnails/' + id + '_1200x1200.jpeg')
                    }

                    // ビデオ系の処理
                    const videoIds = []
                    const videoUrls = [];
                    console.log(res.data)
                    if (res.data.video_list != null) {
                        for (var v in res.data.video_list) {
                            const id = res.data.video_list[v];
                            videoIds.push(id);
                            videoUrls.push(basePath + '/video/' + id + '.mp4');
                        }
                    }
                    // データを書き込む
                    _.commit('setImageUrls', {
                        albumId: data.id, imageIds: imageIds, short: short, tall: tall, videoIds: videoIds, videoUrls: videoUrls, image_url_list_low: res.data.image_url_list_low, image_url_list_mid: res.data.image_url_list_mid, image_url_list_hi: res.data.image_url_list_hi, video_url_list: res.data.video_url_list, title: res.data.title, caption: res.data.caption, user: res.data.user,
                    });
                    return 'SUCCESS'
                }
            } catch (e) {
                if (e.request.status == 401 && e.name == 'AxiosError' && e.code == "ERR_BAD_REQUEST") {
                    return "REQUIRE_PASSWORD"
                }
                return "UNKNOWN_ERROR"
            }
        },
    }
})
