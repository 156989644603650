import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { Icon } from '@iconify/vue'
import VueLoaders from 'vue-loaders'
import 'vue-loaders/dist/vue-loaders.css';
import store from './store'
import "vue-progressive-image/dist/style.css";
import VueProgressiveImage from 'vue-progressive-image'


createApp(App).use(router).use(VueLoaders).use(store).use(VueProgressiveImage).component('Icon', Icon).mount('#app')